/**
 * Copies a string to the clipboard.
 * @param text The string to copy.
 * @param success Function to execute if the copy is successful.
 * @param error Function to execute if the copy fails.
 */
const copyToClipboard = function (
  text: string,
  success?: (text: string) => void,
  error?: (text: string) => void): void {

  if (!text) {
    return;
  }

  try {
    navigator.clipboard.writeText(text)
      .then(() => {
        if (success) {
          success(text);
        }
      })
      .catch(() => {
        if (error) {
          error(text);
        }
      });
  } catch (err) {
    if (error) {
      error(text);
    }
    console.error('Could not copy to clipboard', err);
  }
};

const copyToClipboardWithPromise = function (
  getShareLinkUrl: string,
  success?: () => void,
  error?: () => void): void {
  try {
    if (typeof ClipboardItem && navigator.clipboard.write) {
      // NOTE: Safari locks down the clipboard API to only work when triggered
      //   by a direct user interaction. You can't use it async in a promise.
      //   But! You can wrap the promise in a ClipboardItem, and give that to
      //   the clipboard API.
      //   Found this on https://developer.apple.com/forums/thread/691873
      const clipboardItem = new ClipboardItem({
        'text/plain': fetch(getShareLinkUrl)
          .then(response => response.text())
          .then(clipboardItem => new Blob([clipboardItem], { type: 'text/plain' }))
      });

      navigator.clipboard.write([clipboardItem])
        .then(() => {
          if (success) {
            success();
          }
        })
        .catch(() => {
          if (error) {
            error();
          }
        });
    }
    else {
      // NOTE: Firefox has support for ClipboardItem and navigator.clipboard.write,
      //   but those are behind `dom.events.asyncClipboard.clipboardItem` preference.
      //   Good news is that other than Safari, Firefox does not care about
      //   Clipboard API being used async in a Promise.
      fetch(getShareLinkUrl)
        .then(response => response.text())
        .then(text => navigator.clipboard.writeText(text));
    }
  } catch (error) {
    if (error) {
      error();
    }
  }
};

export {
  copyToClipboard,
  copyToClipboardWithPromise,
};
