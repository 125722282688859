import IComponent from './IComponent';

/** CSS classes used in the Layout Header. */
const cssClasses = {
  headerWrapper: '.layout-header-wrapper',
  header: '.page-header',
  headerTop: 'page-header--top',
  headerSmall: 'page-header--small',
  headerSmallBody: 'page-header-small',
};

/** Handles changes to scroll state. */
const scrollHandler = function (): void {
  const wrapperElement: HTMLElement = document.querySelector(cssClasses.headerWrapper);
  const headerElement: HTMLElement = document.querySelector(cssClasses.header);

  const headerOffset = wrapperElement.offsetTop;
  const headerHeight = wrapperElement.offsetHeight;
  const pageScrollY = window.scrollY;

  if (pageScrollY <= headerOffset) {
    headerElement.classList.add(cssClasses.headerTop);
  } else {
    headerElement.classList.remove(cssClasses.headerTop);
  }

  if (pageScrollY > (headerHeight + headerOffset)) {
    headerElement.classList.add(cssClasses.headerSmall);
    document.body.classList.add(cssClasses.headerSmallBody);
  } else {
    headerElement.classList.remove(cssClasses.headerSmall);
    document.body.classList.remove(cssClasses.headerSmallBody);
  }
};

const register = function (): void {
  window.addEventListener('scroll', scrollHandler);
};

const layoutMenuComponent: IComponent = {
  name: 'Layout Menu',
  register: register
};

export default layoutMenuComponent;
