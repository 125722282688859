import * as $ from 'jquery';
import { copyToClipboard, copyToClipboardWithPromise } from '../utils/clipboard';
import { showToast } from './notification-service';
import { ThemeColor } from '../constants/components';

/**
 * Shares an URL using e-mail.
 * @param url The URL to share.
 * @param title The subject of the mail message.
 * @param generateToken True appends an access token to the URL.
 */
const shareViaMail = function (url: string, title: string, generateToken = true): void {
  const querystring = new URLSearchParams();
  querystring.append('url', url);
  querystring.append('generateToken', generateToken.toString());

  $.ajax({
    type: 'GET',
    url: `/api/share/ShareViaMail?${querystring}`,
    dataType: 'text',
    contentType: 'application/json',
    cache: false,
    success: (result: string): void => {
      window.location.href = 'mailto:?subject=' + title + '&body=' + result;
    },
    error: (jqXHR, textStatus, errorThrown): void => {
      console.error('Error creating share url:', textStatus, errorThrown, jqXHR);
    }
  });
};

/**
 * Shares an URL using Twitter.
 * @param url The URL to share.
 * @param title The title of the Twitter post.
 * @param generateToken True appends an access token to the URL.
 */
const shareViaTwitter = function (url: string, title: string, generateToken = true): void {
  const querystring = new URLSearchParams();
  querystring.append('url', url);
  querystring.append('title', title);
  querystring.append('generateToken', generateToken.toString());

  window.open(`/api/share/ShareViaTwitter?${querystring}`);
};

/**
 * Shares an URL using Facebook.
 * @param url The URL to share.
 * @param title The title of the Facebook post.
 * @param generateToken True appends an access token to the URL.
 */
const shareViaFacebook = function (url: string, title: string, generateToken = true): void {
  const querystring = new URLSearchParams();
  querystring.append('url', url);
  querystring.append('title', title);
  querystring.append('generateToken', generateToken.toString());

  window.open(`/api/share/ShareViaFacebook?${querystring}`);
};

/**
 * Shares an URL using clipboard.
 * @param url The URL to share.
 * @param generateToken True appends an access token to the URL.
 */
const shareViaClipboard = function (url: string, generateToken = true): void {
  const querystring = new URLSearchParams();
  querystring.append('url', url);
  querystring.append('generateToken', generateToken.toString());

  copyToClipboardWithPromise(
    `/api/share/ShareUrlViaCopyLink?${querystring}`,
    () => { showToast('Linket er kopieret til din udklipsholder', 'Linket er kopieret', ThemeColor.Success); },
    () => { showToast('Kunne ikke kopiere linket', 'Fejl', ThemeColor.Danger); }
  );
};

export {
  shareViaMail,
  shareViaTwitter,
  shareViaFacebook,
  shareViaClipboard
};
