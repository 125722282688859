import IComponent from './IComponent';
import InsightsApi from '../apis/insights-api';
import '../elements/insights-toggle-bar';

const register = async function (): Promise<void> {
  const ipInternal: boolean = await InsightsApi.isIpInternal();

  if (!ipInternal)
    return;

  renderInsightsBar();
};

const renderInsightsBar = () => {
  const htmlTest = document.createElement('insights-toggle-bar');
  const element = document.querySelector('body');
  if (element)
    element.prepend(htmlTest);
};

const insightsComponent: IComponent = {
  name: 'Insights',
  register: register
};

export default insightsComponent;
