enum BannerType {
  MobileHeader = 'mobile-header',
  MobileBodySquare = 'mobile-body-square',
  MobileOutstream = 'mobile-in-article',
  MobileTopScroll = 'mobile-topscroller-IO',
  DesktopHeaderWide = 'header-wide',
  DesktopHeaderSquare = 'header-square',
  DesktopHeaderSquareGallery = 'header-square-galleri',
  DesktopBodyGiga = 'body-giga',
  DesktopBodySquare = 'body-square',
  DesktopBodySquareArticle = 'body-square-artikel',
  DesktopBodyWide = 'body-wide',
  DesktopOutstream = 'in-article',
  DesktopTopScroll = 'topscroller-IO',
  SkyscraperLeft = 'skyscraper-left',
  SkyscraperRight = 'skyscraper-right'
}

export default BannerType;
