import { TimeFilter } from '../apis/insights-api';
import { HTMLInsightsBarElement } from './insights-bar';

class HTMLInsightsToggleBarElement extends HTMLElement {
  timeFilterButtons: NodeListOf<HTMLElement>;
  insightBars: NodeListOf<HTMLInsightsBarElement>;

  constructor() {
    super();
  }

  connectedCallback() {
    //Setup toggle bar
    const template = document.getElementById('insights-toggle-bar') as HTMLTemplateElement;
    const templateContent = template.content;
    this.prepend(templateContent.cloneNode(true));

    this.setupInsightBars();

    this.registerEventListeners();

    this.initButtons();
  }

  setupInsightBars() {
    const localStorageEnabled = localStorage.getItem('insights-enabled') ?? 'true';
    const enabled: boolean = localStorageEnabled == 'true' ? true : false;

    const articles = document.querySelectorAll('article[data-articleid]') as NodeListOf<HTMLElement>;

    articles.forEach(article => {
      const isArticleView = article.querySelector('#article-start');

      const insightsBarElement = document.createElement('insights-bar') as HTMLInsightsBarElement;
      const url = this.getUrlForTeaser(article);

      const picture = article.querySelector('picture') as HTMLElement;
      insightsBarElement.articleUrl = url;

      if (!enabled) {
        insightsBarElement.style.display = 'none';
      }

      //if article view, place on picture, otherwise place on teaser
      if (isArticleView && picture) {
        picture.prepend(insightsBarElement);
      } else {
        article.prepend(insightsBarElement);
      }
    });

    this.insightBars = document.querySelectorAll('insights-bar');
  }

  registerEventListeners() {
    const refreshButton = this.querySelector('[data-refresh]');
    if (refreshButton) {
      refreshButton.addEventListener('click', this.handleRefreshClicked.bind(this));
    }

    const closeButton = this.querySelector('[data-close]');
    if (closeButton)
      closeButton.addEventListener('click', () => { this.style.display = 'none'; });

    this.timeFilterButtons = this.querySelectorAll('[data-time-scope]');
    this.timeFilterButtons.forEach(button => {
      button.addEventListener('click', this.handleTimeScopeChange.bind(this));
    });

    this.querySelectorAll('[data-enable]').forEach(button => {
      button.addEventListener('click', this.handleEnableToggle.bind(this));
    });
  }

  initButtons() {
    //Initialize selected timefilter button
    const timeScope = localStorage.getItem('insights-time-scope') ?? 'Alltime';
    const initialSelectedTimeScopeBtn = document.querySelector(`[data-time-scope=${timeScope}]`) as HTMLElement;
    this.toggleButtons(initialSelectedTimeScopeBtn);

    //Initialize selected enable button
    const enabled = localStorage.getItem('insights-enabled') ?? 'true';
    const initialEnabledBtn = this.querySelector(`[data-enable=${enabled}]`) as HTMLElement;
    this.toggleButtons(initialEnabledBtn);
  }

  reload() {
    this.insightBars.forEach(bar => {
      bar.selectedTimeFilter = TimeFilter[localStorage.getItem('insights-time-scope') as keyof typeof TimeFilter];
      bar.loadData();
    });
  }

  handleEnableToggle(e: Event): void {
    const btn = e.target as HTMLElement;

    this.toggleButtons(btn);
    const enabled: boolean = btn.dataset.enable == 'true' ? true : false;

    localStorage.setItem('insights-enabled', btn.dataset.enable);

    this.insightBars.forEach(bar => {
      if (enabled) {
        bar.style.display = 'flex';
      } else {
        bar.style.display = 'none';
      }
    });
  }

  handleRefreshClicked(e: Event): void {
    e.preventDefault();
    this.reload();
  }

  handleTimeScopeChange(e: Event): void {
    e.preventDefault();
    const timeScope = e.target as HTMLElement;
    localStorage.setItem('insights-time-scope', timeScope.dataset.timeScope);
    this.toggleButtons(timeScope);
    this.reload();
  }

  public toggleButtons(btn: HTMLElement) {
    const buttonGroup = btn.parentElement.querySelectorAll('button') as NodeListOf<HTMLElement>;
    buttonGroup.forEach(btn => {
      btn.classList.remove('btn-primary');
      btn.classList.add('btn-outline-dark');
    });
    btn.classList.add('btn-primary');
    btn.classList.remove('btn-outline-dark');
  }

  getUrlForTeaser(teaser: HTMLElement): string {
    let url: string;
    let protocol: RegExpMatchArray;
    if (!this.IsStringEmpty(teaser.dataset.url)) {
      protocol = teaser.dataset.url.match(/^http(s?):\/\/[^/]+/);
    }
    if (protocol === null || protocol === undefined || this.IsStringEmpty(protocol[0])) {
      url = window.location.hostname.toLowerCase() === 'localhost' ? 'https://nordjyske.dk' : window.location.origin;
    }

    if (!this.IsStringEmpty(teaser.dataset.url)) {
      url += teaser.dataset.url;
    } else {
      url += teaser.querySelector('[itemprop=url]').getAttribute('href');
    }

    return url;
  }

  IsStringEmpty(value: string) {
    return (value == null || value.trim().length === 0);
  }
}

customElements.define('insights-toggle-bar', HTMLInsightsToggleBarElement);
