import IComponent from '../components/IComponent';

const sportSections: string[] = [
  'fodbold',
  'h%C3%A5ndbold',
  'ishockey',
  'aab'
];

const getUrlTargeting = function () {
  const urlMatches = window.location.toString().match(/^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/);
  const matchedAuthority = urlMatches[4] || '';
  const matchedPath = (urlMatches[5] || '').replace(/(.)\/$/, '$1');
  const matchedQuery = urlMatches[7] || '';

  const params = matchedQuery.replace(/=/ig, ':').split('&');

  return {
    'UrlHost': matchedAuthority,
    'UrlPath': matchedPath,
    'UrlQuery': params
  };
};

const registerKeyValues = function () {
  // notify GAM of the section we are currently loading banners for
  window.googletag = window.googletag || { cmd: [] };
  // googletag.cmd = googletag.cmd || [];

  window.googletag.cmd.push(function () {
    // window.googletag.pubads().collapseEmptyDivs();
    window.googletag.pubads().enableSingleRequest();

    const targeting = getUrlTargeting();
    type targetingKey = keyof typeof targeting;

    for (const key in targeting) {
      // eslint-disable-next-line no-prototype-builtins
      if (targeting.hasOwnProperty(key)) {
        const value = targeting[key as targetingKey];

        window.googletag.pubads().setTargeting(key, value);
      }
    }

    let section = window.currentSection;
    if (section) {
      /* Sport sections should use page_tag instead */
      if (sportSections.includes(section)) {
        window.googletag.pubads().setTargeting('Page_tag', section);
        section = 'sport';
      }
      window.googletag.pubads().setTargeting('Sektion', section);
    }

    if (window.pagetype) {
      window.googletag.pubads().setTargeting('Page_type', window.pagetype);
    }

    if (window.userloginstate) {
      window.googletag.pubads().setTargeting('User', window.userloginstate);
    }
  });
};

/** Initializes Google Ad Manager. */
const register = () => {
  document.addEventListener('DOMContentLoaded', () => {
    window.pendingScriptExecutions.push({
      callback: registerKeyValues, context: this
    });
  });
};

const GoogleAdManagerComponent: IComponent = {
  name: 'GoogleAdManager',
  register: register
};

export default GoogleAdManagerComponent;
