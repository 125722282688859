export interface SubscribeRequest {
  FirstName: string;
  LastName: string;
  FullName: string;
  Email: string;
  Newsletter: string;
}

const subscribe = function (request: SubscribeRequest): Promise<Response> {
  const url = '/api/NewsletterSettings/subscribe';

  const formData = new FormData();
  formData.append('Email', request.Email);
  formData.append('FirstName', request.FirstName);
  formData.append('LastName', request.LastName);
  formData.append('FullName', request.FullName);
  formData.append('Newsletter', request.Newsletter);

  const requestInit: RequestInit = {
    method: 'POST',
    body: formData,
  };

  return fetch(url, requestInit);
};

export default {
  subscribe,
};
