import IComponent from '../components/IComponent';

/**
 * Registers the provided component.
 * @param component The component to register.
 */
const registerComponent = (component: IComponent): void => {
  try {
    console.debug('Registering component:', component.name);
    component.register();
  } catch (ex) {
    console.error('Failed to register component:', component.name, ex);
  }
};

/**
 * Registers the provided components.
 * @param components The components to register.
 */
const registerComponents = (components: IComponent[]): void => {
  components.forEach(registerComponent);
};

/**
 * Used to manage components.
 */
export default {
  registerComponent,
  registerComponents
};
