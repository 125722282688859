import * as $ from 'jquery';
import IComponent from './IComponent';

const register = function (): void {
  document.addEventListener('DOMContentLoaded', () => {
    $('.important-info-bar').on('click', '.btn-close', (event: JQuery.ClickEvent) => {
      event.preventDefault();
      $('.important-info-bar').hide('slow');
    });
  });
};

const importantInfoBarComponent: IComponent = {
  name: 'Important Info Bar',
  register: register
};

export default importantInfoBarComponent;
