/**
 * Process tracking queue.
 */
const processTrackingQueue = function (): void {
  console.debug('Tracking provider loaded: Gemius');

  if (!window.trackingScriptsEnabled.Gemius) {
    console.debug('Gemius tracking scripts not enabled - do not process tracking queue');
    return;
  }

  console.debug('Processing queue: Gemius');

  // first capture push function, so new calls are executed immediately
  window.trackingQueue.Gemius.push = function (callbackObj) {
    if (typeof (callbackObj) === 'function') {
      callbackObj();
    }
    return 0;
  };

  // then empty the existing queue of callbacks already added
  window.trackingQueue.Gemius.forEach(function (v) {
    window.trackingQueue.Gemius.push(v);
  });
};

/**
 *
 */
const register = function () {
  console.debug('Registering tracking provider: Gemius');

  if (typeof window.pp_gemius_hit !== 'undefined') {
    processTrackingQueue();
  }
  else {
    window.addEventListener('gm_loaded', () => {
      processTrackingQueue();
    });
  }
};

/**
 *
 * @param imageIndex
 */
const sendImagePageView = function (imageIndex: number) {
  window.trackingQueue.Gemius.push(() => {
    // When previewing articles scripts are disabled and the following will throw an exception breaking all gallery functionality if not caught
    try {
      window.pp_gemius_hit(window.pp_gemius_identifier, 'pictureid=' + imageIndex.toString());
    } catch {
      // Preview throws an exception in test here because trackingscripts are disabled through cshtml.
    }
  });
};

/**
 * Encapsulates all Gemius tracking functionality.
 */
export default {
  register,
  sendImagePageView,
};
