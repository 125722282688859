/**
 * Process tracking queue.
 */
const processTrackingQueue = function (): void {
  console.debug('Tracking provider loaded: GoogleAnalytics4');

  if (!window.trackingScriptsEnabled.Google) {
    console.debug('GoogleAnalytics4 tracking scripts not enabled - do not process tracking queue');
    return;
  }

  console.debug('Processing queue: GoogleAnalytics4');

  // first capture push function, so new calls are executed immediately
  window.trackingQueue.GoogleAnalytics4.push = function (callbackObj) {
    if (typeof (callbackObj) === 'function') {
      callbackObj();
    }
    return 0;
  };

  // then empty the existing queue of callbacks already added
  window.trackingQueue.GoogleAnalytics4.forEach(function (v) {
    window.trackingQueue.GoogleAnalytics4.push(v);
  });
};

/**
 *
 */
const register = function () {
  console.debug('Registering tracking provider: Google Analytics 4');

  if (typeof gtag !== 'undefined') {
    processTrackingQueue();
  }
  else {
    window.addEventListener('ga4_loaded', () => {
      processTrackingQueue();
    });
  }
};

/**
 *
 * @param hasAcces
 * @param hasPaywall
 */
const sendPaywallEvent = function (hasAcces: boolean, hasPaywall: boolean) {
  if (hasPaywall) {
    window.trackingQueue.GoogleAnalytics4.push(() => {
      gtag('event', 'article_has_pay_wall', {
        'behind_paywall': hasAcces,
      });
    });
  }
};

/**
 *
 * @param hasAcces
 * @param hasPaywall
 */
const sendUserEngagemenBehindPaywall = function (hasAcces: boolean, hasPaywall: boolean) {
  if (hasAcces && hasPaywall) {
    const start = Date.now();
    window.addEventListener('beforeunload', () => {
      const passedTimeInSeconds = (Math.floor((Date.now() - start) / 1000));
      if (passedTimeInSeconds <= 1)
        return;

      window.trackingQueue.GoogleAnalytics4.push(() => {
        if (window.trackingScriptsEnabled.Google && gtag !== undefined) {
          gtag('event', 'user_engagement_behind_paywall', {
            'userEngagementInSeconds': passedTimeInSeconds,
          });
        }
      });
    });
  }
};

/**
 *Encapsulates all Google Analytics 4 functionality.
 */
export default {
  register,
  sendPaywallEvent,
  sendUserEngagemenBehindPaywall,
};
