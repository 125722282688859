import BannerType from './banner-type';

class AdUnitSet {
  /** Current number of instances. */
  private number: number;
  /** Once number reaches max, this number is incremented. */
  private postFix: number;
  /** Maximum number of instances. */
  private max: number;

  /**
   * Creates a new instance of AdUnitSet.
   * @param max Maximum number of instances.
   */
  constructor(max: number) {
    this.number = 0;
    this.postFix = null;
    this.max = max;
  }

  /**
   * Gets the next available number in the set.
   * @returns an object with number and postfix.
   */
  public getNextAvailableNumber(): { number: number, postFix: number } {
    if (this.number < this.max) {
      this.number++;
    } else {
      if (!this.postFix) {
        this.postFix = 1;
      } else {
        this.postFix++;
      }
    }

    return {
      number: this.number,
      postFix: this.postFix
    };
  }
}

abstract class AdUnitContext {
  private static readonly mobileHeaderContext = new AdUnitSet(1);
  private static readonly mobileBodySquareContext = new AdUnitSet(6);
  private static readonly mobileOutstreamContext = new AdUnitSet(1);
  private static readonly mobileTopScrollContext = new AdUnitSet(1);
  private static readonly desktopHeaderWideContext = new AdUnitSet(1);
  private static readonly desktopHeaderSquareContext = new AdUnitSet(1);
  private static readonly desktopHeaderSquareGalleryContext = new AdUnitSet(1);
  private static readonly desktopBodyGigaContext = new AdUnitSet(1);
  private static readonly desktopBodySquareContext = new AdUnitSet(6);
  private static readonly desktopBodySquareArticleContext = new AdUnitSet(1);
  private static readonly desktopBodyWideContext = new AdUnitSet(6);
  private static readonly desktopOutstreamContext = new AdUnitSet(1);
  private static readonly desktopTopScrollContext = new AdUnitSet(1);
  private static readonly skyscraperLeftContext = new AdUnitSet(1);
  private static readonly skyscraperRightContext = new AdUnitSet(1);

  /**
   * Returns the context for a given type of ad unit.
   * @param type The set type.
   */
  static getSet(type: BannerType): AdUnitSet {
    switch (type) {
      case BannerType.MobileHeader:
        return this.mobileHeaderContext;

      case BannerType.MobileBodySquare:
        return this.mobileBodySquareContext;

      case BannerType.MobileOutstream:
        return this.mobileOutstreamContext;

      case BannerType.MobileTopScroll:
        return this.mobileTopScrollContext;

      case BannerType.DesktopHeaderWide:
        return this.desktopHeaderWideContext;

      case BannerType.DesktopHeaderSquare:
        return this.desktopHeaderSquareContext;

      case BannerType.DesktopHeaderSquareGallery:
        return this.desktopHeaderSquareGalleryContext;

      case BannerType.DesktopBodyGiga:
        return this.desktopBodyGigaContext;

      case BannerType.DesktopBodySquare:
        return this.desktopBodySquareContext;

      case BannerType.DesktopBodySquareArticle:
        return this.desktopBodySquareArticleContext;

      case BannerType.DesktopBodyWide:
        return this.desktopBodyWideContext;

      case BannerType.DesktopOutstream:
        return this.desktopOutstreamContext;

      case BannerType.DesktopTopScroll:
        return this.desktopTopScrollContext;

      case BannerType.SkyscraperLeft:
        return this.skyscraperLeftContext;

      case BannerType.SkyscraperRight:
        return this.skyscraperRightContext;

      default:
        return null;
    }
  }
}

export default AdUnitContext;
