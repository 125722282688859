import * as $ from 'jquery';
import TrackingService from './tracking-service';

/** Track clicks via data attributes */
$(document.body)
  .on('click', '[data-trackclicks]', (event: JQuery.ClickEvent) => {
    const target = $(event.currentTarget);

    // abandon if no active link
    const link = <HTMLAnchorElement>target[0];
    if ($(link).is('a') && link.href.length === 0)
      return;

    if (event.isDefaultPrevented())
      return;

    const category = target.data('category');
    const label = target.data('label');

    TrackingService.GoogleAnalytics.sendLinkClickedEvent(category, label);
  });

  /** Track native pageviews via data attribute */
  $(function() {
    $('[data-tracknative]').each((index, element) => {
      const id = $(element).data('id');
      const location = $(element).data('tracknative');

      TrackingService.GoogleAnalytics.sendNativePageView(id, location);
    });
});
