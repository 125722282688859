import IComponent from './IComponent';

let firstSlide = true;

const register = function (): void {
  document.addEventListener('DOMContentLoaded', () => {
    setTimeout(translateFunction, 3500);
  });
};

const currentNewsBarComponenet: IComponent = {
  name: 'Current News Bar',
  register: register
};

export default currentNewsBarComponenet;

async function translateFunction(): Promise<void> {
  const slider = document.querySelector('#currentNewsBar') as HTMLElement;
  if (!slider) {
    return;
  }

  if (!firstSlide) {
    //append clone and remove first, to make infinite loop
    const node = document.querySelectorAll('.current-news-bar__slide')[0];
    const clone = node.cloneNode(true);
    slider.append(clone);
    node.remove();
  } else {
    firstSlide = false;
  }

  slider.style.transform = 'translateX(0px)';
  slider.style.transition = ' all 0s';

  const currentSlide = document.querySelectorAll('.current-news-bar__slide')[0] as HTMLElement;

  const width = currentSlide.offsetWidth;
  const transformTime = width / 100;

  slider.style.transform = `translateX(-${width}px)`;
  slider.style.transition = `all ${transformTime}s ease-in-out`;

  await sleep(transformTime * 1000);

  setTimeout(translateFunction, 3500);
}

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
