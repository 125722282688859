import * as $ from 'jquery';
import {
  shareViaMail,
  shareViaTwitter,
  shareViaFacebook,
  shareViaClipboard
} from '../services/share-service';

enum ShareType {
  Clipboard,
  Mail,
  Facebook,
  Twitter,
}

const shareAttr = 'share';
const titleAttr = 'share-title';
const urlAttr = 'share-url';
const tokenAttr = 'share-token';

$(document.body)
  .on('click', `[data-${shareAttr}]`, (event: JQuery.ClickEvent) => {
    event.preventDefault();

    const target = $(event.currentTarget);
    const shareType = target.data(shareAttr);
    const title = target.data(titleAttr) || document.title;
    const url = target.data(urlAttr) || window.location.href;
    const generateToken = typeof target.data(tokenAttr) !== 'undefined';

    switch (ShareType[shareType as keyof typeof ShareType]) {
      case ShareType.Mail:
        shareViaMail(url, title, generateToken);
        break;

      case ShareType.Facebook:
        shareViaFacebook(url, title, generateToken);
        break;

      case ShareType.Twitter:
        shareViaTwitter(url, title, generateToken);
        break;

      default:
        shareViaClipboard(url, generateToken);
        break;
    }
  });
