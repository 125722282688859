import IComponent from './IComponent';
import { isFunction } from '../utils/inspect';

const indexedPurposesSpecialFeaturesList = {
  '1': {
    'id': 1,
    'name': 'Use precise geolocation data'
  },
  '2': {
    'id': 2,
    'name': 'Actively scan device characteristics for identification'
  }
};

const indexedPurposesList = {
  '1': {
    'id': 1,
    'name': 'Store and/or access information on a device'
  },
  '2': {
    'id': 2,
    'name': 'Select basic ads'
  },
  '3': {
    'id': 3,
    'name': 'Create a personalised ads profile'
  },
  '4': {
    'id': 4,
    'name': 'Select personalised ads'
  },
  '5': {
    'id': 5,
    'name': 'Create a personalised content profile'
  },
  '6': {
    'id': 6,
    'name': 'Select personalised content'
  },
  '7': {
    'id': 7,
    'name': 'Measure ad performance'
  },
  '8': {
    'id': 8,
    'name': 'Measure content performance'
  },
  '9': {
    'id': 9,
    'name': 'Apply market research to generate audience insights'
  },
  '10': {
    'id': 10,
    'name': 'Develop and improve products'
  }
};

/** Set consent defaults. */
const setDefaultValues = function (): void {
  window.thirdPartyConsentAccepted = {
    StoreAndAccessInformation: false,
    SelectBasicAds: false,
    CreateAdsProfile: false,
    SelectPersonalisedAds: false,
    CreatePersonalisedProfile: false,
    SelectPersonalisedContent: false,
    MeasureAdPerformance: false,
    MeasureContentPerformance: false,
    GenerateAudienceInsights: false,
    DevelopProducts: false,
    UseGeolocationData: false,
    ScanDevice: false
  };

  window.trackingScriptsEnabled = {
    allConsentsAndTrackings: false,
    ChartBeat: false,
    Facebook: false,
    Google: false,
    LinkedIn: false,
    Gemius: false,
    Weply: false,
    Agillic: false,
    Maze: false
  };
};

/**
 * Filters object based on name and returns the id.
 * @param object
 * @param value
 */
const filterObject = function (object: any, value: any): any {
  return Object.keys(object).reduce(function (r, e) {
    if (object[e].name === value) {
      r = object[e].id;
    }
    return r;
  }, {});
};

const getUrlTargeting = function (): object {
  const urlMatches = window.location.toString().match(/^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/);
  const matchedAuthority = urlMatches[4] || '';
  const matchedPath = (urlMatches[5] || '').replace(/(.)\/$/, '$1');
  const matchedQuery = urlMatches[7] || '';

  const params = matchedQuery.replace(/=/ig, ':').split('&');

  return {
    UrlHost: matchedAuthority,
    UrlPath: matchedPath,
    UrlQuery: params
  };
};

/**
 * Check if function exists before calling it.
 * @param fn
 */
const callFunction = function (fn: any): void {
  if (isFunction(fn)) {
    fn();
  }
};

/** Process queued up script executions such as widgets and other tracking. */
const pendingScript = function (): void {
  // first capture push function, so new calls are executed immediately
  window.pendingScriptExecutions.push = function (callbackObj) {
    if (isFunction(callbackObj.callback)) {
      callbackObj.callback.call(callbackObj.context);
    }
    return 0;
  };
  // then empty the existing queue of callbacks already added
  window.pendingScriptExecutions.forEach(function (v) {
    window.pendingScriptExecutions.push(v);
  });
};

/** Enforce the user consent choices. */
const enforceConsent = function (thirdPartyPurposeConsentList: any, thirdPartyPurposeConsentListSpecialFeatures: any): void {
  /* **********************************************
   * Extract user consent settings from QuantCast *
   * **********************************************/

  // set consent choices
  const thirdPartyConsentChoices = {
    StoreAndAccessInformation: thirdPartyPurposeConsentList[filterObject(indexedPurposesList, 'Store and/or access information on a device')],
    SelectBasicAds: thirdPartyPurposeConsentList[filterObject(indexedPurposesList, 'Select basic ads')],
    CreateAdsProfile: thirdPartyPurposeConsentList[filterObject(indexedPurposesList, 'Create a personalised ads profile')],
    SelectPersonalisedAds: thirdPartyPurposeConsentList[filterObject(indexedPurposesList, 'Select personalised ads')],
    CreatePersonalisedProfile: thirdPartyPurposeConsentList[filterObject(indexedPurposesList, 'Create a personalised content profile')],
    SelectPersonalisedContent: thirdPartyPurposeConsentList[filterObject(indexedPurposesList, 'Select personalised content')],
    MeasureAdPerformance: thirdPartyPurposeConsentList[filterObject(indexedPurposesList, 'Measure ad performance')],
    MeasureContentPerformance: thirdPartyPurposeConsentList[filterObject(indexedPurposesList, 'Measure content performance')],
    GenerateAudienceInsights: thirdPartyPurposeConsentList[filterObject(indexedPurposesList, 'Apply market research to generate audience insights')],
    DevelopProducts: thirdPartyPurposeConsentList[filterObject(indexedPurposesList, 'Develop and improve products')],
    UseGeolocationData: thirdPartyPurposeConsentListSpecialFeatures[filterObject(indexedPurposesSpecialFeaturesList, 'Use precise geolocation data')],
    ScanDevice: thirdPartyPurposeConsentListSpecialFeatures[filterObject(indexedPurposesSpecialFeaturesList, 'Actively scan device characteristics for identification')]
  };

  window.thirdPartyConsentAccepted.StoreAndAccessInformation = thirdPartyConsentChoices.StoreAndAccessInformation !== undefined ? thirdPartyConsentChoices.StoreAndAccessInformation : false;
  window.thirdPartyConsentAccepted.SelectBasicAds = thirdPartyConsentChoices.SelectBasicAds !== undefined ? thirdPartyConsentChoices.SelectBasicAds : false;
  window.thirdPartyConsentAccepted.CreateAdsProfile = thirdPartyConsentChoices.CreateAdsProfile !== undefined ? thirdPartyConsentChoices.CreateAdsProfile : false;
  window.thirdPartyConsentAccepted.SelectPersonalisedAds = thirdPartyConsentChoices.SelectPersonalisedAds !== undefined ? thirdPartyConsentChoices.SelectPersonalisedAds : false;
  window.thirdPartyConsentAccepted.CreatePersonalisedProfile = thirdPartyConsentChoices.CreatePersonalisedProfile !== undefined ? thirdPartyConsentChoices.CreatePersonalisedProfile : false;
  window.thirdPartyConsentAccepted.SelectPersonalisedContent = thirdPartyConsentChoices.SelectPersonalisedContent !== undefined ? thirdPartyConsentChoices.SelectPersonalisedContent : false;
  window.thirdPartyConsentAccepted.MeasureAdPerformance = thirdPartyConsentChoices.MeasureAdPerformance !== undefined ? thirdPartyConsentChoices.MeasureAdPerformance : false;
  window.thirdPartyConsentAccepted.MeasureContentPerformance = thirdPartyConsentChoices.MeasureContentPerformance !== undefined ? thirdPartyConsentChoices.MeasureContentPerformance : false;
  window.thirdPartyConsentAccepted.GenerateAudienceInsights = thirdPartyConsentChoices.GenerateAudienceInsights !== undefined ? thirdPartyConsentChoices.GenerateAudienceInsights : false;
  window.thirdPartyConsentAccepted.DevelopProducts = thirdPartyConsentChoices.DevelopProducts !== undefined ? thirdPartyConsentChoices.DevelopProducts : false;
  window.thirdPartyConsentAccepted.UseGeolocationData = thirdPartyConsentChoices.UseGeolocationData !== undefined ? thirdPartyConsentChoices.UseGeolocationData : false;
  window.thirdPartyConsentAccepted.ScanDevice = thirdPartyConsentChoices.ScanDevice !== undefined ? thirdPartyConsentChoices.ScanDevice : false;

  window.trackingScriptsEnabled.allConsentsAndTrackings = window.thirdPartyConsentAccepted.StoreAndAccessInformation &&
    window.thirdPartyConsentAccepted.SelectBasicAds &&
    window.thirdPartyConsentAccepted.CreateAdsProfile &&
    window.thirdPartyConsentAccepted.SelectPersonalisedAds &&
    window.thirdPartyConsentAccepted.CreatePersonalisedProfile &&
    window.thirdPartyConsentAccepted.SelectPersonalisedContent &&
    window.thirdPartyConsentAccepted.MeasureAdPerformance &&
    window.thirdPartyConsentAccepted.MeasureContentPerformance &&
    window.thirdPartyConsentAccepted.GenerateAudienceInsights &&
    window.thirdPartyConsentAccepted.DevelopProducts &&
    window.thirdPartyConsentAccepted.UseGeolocationData &&
    window.thirdPartyConsentAccepted.ScanDevice;

  // enable Facebook, LinkedIn and Google tracking
  window.trackingScriptsEnabled.Facebook = window.trackingScriptsEnabled.LinkedIn = window.trackingScriptsEnabled.Google =
    window.thirdPartyConsentAccepted.StoreAndAccessInformation &&
    window.thirdPartyConsentAccepted.MeasureContentPerformance &&
    window.thirdPartyConsentAccepted.DevelopProducts &&
    window.thirdPartyConsentAccepted.ScanDevice &&
    window.thirdPartyConsentAccepted.CreateAdsProfile &&
    window.thirdPartyConsentAccepted.SelectPersonalisedAds &&
    window.thirdPartyConsentAccepted.MeasureAdPerformance &&
    window.thirdPartyConsentAccepted.GenerateAudienceInsights &&
    window.thirdPartyConsentAccepted.SelectPersonalisedContent &&
    window.thirdPartyConsentAccepted.SelectBasicAds &&
    window.thirdPartyConsentAccepted.CreatePersonalisedProfile;

  // enable Gemius tracking
  window.trackingScriptsEnabled.Gemius =
    window.thirdPartyConsentAccepted.StoreAndAccessInformation &&
    window.thirdPartyConsentAccepted.MeasureContentPerformance &&
    window.thirdPartyConsentAccepted.DevelopProducts &&
    window.thirdPartyConsentAccepted.ScanDevice;

  // chartbeat tracking and testing
  window.trackingScriptsEnabled.ChartBeat =
    window.thirdPartyConsentAccepted.MeasureContentPerformance &&
    window.thirdPartyConsentAccepted.GenerateAudienceInsights;

  // Weply
  window.trackingScriptsEnabled.Weply =
    window.thirdPartyConsentAccepted.StoreAndAccessInformation &&
    window.thirdPartyConsentAccepted.CreatePersonalisedProfile &&
    window.thirdPartyConsentAccepted.SelectPersonalisedContent &&
    window.thirdPartyConsentAccepted.MeasureContentPerformance &&
    window.thirdPartyConsentAccepted.GenerateAudienceInsights &&
    window.thirdPartyConsentAccepted.DevelopProducts;

  /* enable Agillic tracking */
  window.trackingScriptsEnabled.Agillic =
    window.thirdPartyConsentAccepted.CreateAdsProfile &&
    window.thirdPartyConsentAccepted.MeasureAdPerformance &&
    window.thirdPartyConsentAccepted.StoreAndAccessInformation;

  /* enable Maze tracking */
  window.trackingScriptsEnabled.Maze =
    window.thirdPartyConsentAccepted.CreatePersonalisedProfile;

  /* ***************************************************************
   * Determine which scripts and partials should be enabled/loaded *
   * ***************************************************************/

  // gemius
  if (window.trackingScriptsEnabled.Gemius) {
    callFunction(window.enableGemiusTracking); // _GemiusTracking.cshtml
  } else {
    callFunction(window.disableGemiusTracking); // _GemiusTracking.cshtml
  }

  // facebook script
  if (window.trackingScriptsEnabled.Facebook) {
    window.dataLayer.push({ event: 'FacebookConsent' });
  }

  // linkedIn script
  if (window.trackingScriptsEnabled.LinkedIn) {
    callFunction(window.linkedInGrantConsent); // _LinkedInInsightTag.cshtml
  }
  else {
    callFunction(window.linkedInRevokeConsent); // _LinkedInInsightTag.cshtml
  }

  // google analytics script
  if (window.trackingScriptsEnabled.Google) {
    window.dataLayer.push({ event: 'GoogleConsent' });
    callFunction(window.googleEnableAnalytics); // _GaTracking.cshtml
  }
  else {
    callFunction(window.googleRemoveFirstPartyCookies); // _GaTracking.cshtml
  }

  // chartbeat tracking and testing
  if (typeof window.setupChartBeatTracking === 'function') {
    callFunction(window.setupChartBeatTracking(window.trackingScriptsEnabled.ChartBeat));
  }

  // Weply script
  if (window.trackingScriptsEnabled.Weply) {
    callFunction(window.weplyGrantConsent); // _Weply.cshtml
  }

  // agillic
  if (window.trackingScriptsEnabled.Agillic) {
    window.dataLayer.push({ event: 'AgillicConsent' });
  }

  // maze
  if (window.trackingScriptsEnabled.Maze) {
    window.dataLayer.push({ event: 'MazeConsent' });
  }

  /* **************************************************
   * enable banners after enabling/disabling scripts  *
   * **************************************************/
  if (window.currentSection !== null && window.currentSection !== undefined && window.currentSection.length > 0) {
    // notify GAM of the section we are currently loading banners for
    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];

    googletag.cmd.push(function () {
      googletag.pubads().collapseEmptyDivs();
      googletag.pubads().enableSingleRequest();

      const targeting = getUrlTargeting();
      Object.keys(targeting).forEach((key) => {
        const value = targeting[key as keyof typeof targeting];
        googletag.pubads().setTargeting(key, value);
      });

      //Targets the Sektion key with the values of currentSection and the Nordjyske_ROS values, depending on which fits better.
      googletag.cmd.push(function () {
        googletag.pubads().setTargeting('Sektion', ['NS_ROS', window.currentSection]);
      });
    });
  }

  callFunction(window.enableBanners);

  pendingScript();
};

/** Get the user consent choices. */
const getUserConsentChoices = function (): void {

  try {
    // get transparency and consent data - check 3rd party cookie consent
    __tcfapi('getTCData', 2, (tcData, success) => {
      if (success && tcData.gdprApplies) {
        const thirdPartyPurposeConsentList = tcData.purpose.consents;
        const thirdPartyPurposeConsentListSpecialFeatures = tcData.specialFeatureOptins;
        enforceConsent(thirdPartyPurposeConsentList, thirdPartyPurposeConsentListSpecialFeatures);
      }
    });
  } catch {
    // Preview throws an exception in test here because trackingscripts are disabled through cshtml.
  }
};

/** Registers the cookie consent component. */
const register = function (): void {
  setDefaultValues();

  document.addEventListener('DOMContentLoaded', () => {
    try {
      if (window.features.useQc) {
        // setup event listener for quantcast v2 events
        // https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20CMP%20API%20v2.md
        __tcfapi('addEventListener', 2, (tcData, success) => {

          if (success && tcData.eventStatus === 'tcloaded') {
            // this function is called when the page is loaded

            if (tcData.tcString) {
              // we got the consent string already => proceed
              getUserConsentChoices();
            }
          }

          if (success && tcData.eventStatus === 'useractioncomplete') {
            // this function is called when:
            // - the user accepts / rejects / updates the consent terms
            // - the user dismisses the consent box by clicking the 'x'
            getUserConsentChoices();
          }
        });
      } else {
        try {
          callFunction(window.enableBanners);
        } catch (e) {
          console.error('Failed to enable banners', e);
        }
        window.trackingScriptsEnabled.Google = true;
        try {
          callFunction(window.googleEnableAnalytics);
        } catch (e) {
          console.error('Failed to enable Analytics', e);
        }
        try {
          callFunction(window.enableGemiusTracking);
        } catch (e) {
          console.error('Failed to enable Gemius', e);
        }

        pendingScript();
      }
    } catch (e) {
      // Preview throws an exception in test here because trackingscripts are disabled through cshtml.
      console.error('Failed to initialize Cookie Consent', e);
    }
  });
};

const CookieConsentComponent: IComponent = {
  name: 'Cookie Consent',
  register: register
};

export default CookieConsentComponent;
