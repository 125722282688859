/**
 * Process tracking queue.
*/
const processTrackingQueue = function (): void {
  console.debug('Tracking provider loaded: Facebook');

  if (!window.trackingScriptsEnabled.Facebook) {
    console.debug('Facebook tracking scripts not enabled - do not process tracking queue');
    return;
  }

  console.debug('Processing queue: Facebook');

  // first capture push function, so new calls are executed immediately
  window.trackingQueue.Facebook.push = function (callbackObj) {
    if (typeof (callbackObj) === 'function') {
      callbackObj();
    }
    return 0;
  };

  // then empty the existing queue of callbacks already added
  window.trackingQueue.Facebook.forEach(function (v) {
    window.trackingQueue.Facebook.push(v);
  });
};

/**
 *
 */
const register = function () {
  console.debug('Registering tracking provider: Facebook');

  if (typeof fbq !== 'undefined') {
    processTrackingQueue();
  }
  else {
    window.addEventListener('fb_loaded', () => {
      processTrackingQueue();
    });
  }
};

/**
 *
 * @param facebookPixelId
 */
const initTracker = function (facebookPixelId: string) {
  window.trackingQueue.Facebook.push(() => {
    fbq('init', facebookPixelId);
  });
};

/**
 *
 */
const sendPageView = function () {
  window.trackingQueue.Facebook.push(() => {
    fbq('track', 'PageView');
  });
};

/**
 *
 * @param funnelDescription
 * @param productPrice
 */
const sendProductPurchased = function (funnelDescription: string, productPrice?: number) {
  window.trackingQueue.Facebook.push(() => {
    if (productPrice)
      fbq('track', 'Purchase', { value: productPrice, currency: 'DKK', content_name: `'${funnelDescription}'` });
    else
      fbq('track', 'Purchase', { content_name: `'${funnelDescription}'` });
  });
};

/**
 * Encapsulates all Facebook tracking functionality.
 */
export default {
  register,
  initTracker,
  sendPageView,
  sendProductPurchased,
};
